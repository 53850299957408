import styles from "./ParagraphText.module.scss";
import ParagraphTextType from "./ParagraphTextType";

const ParagraphText = (props: ParagraphTextType) => {
  const {id,text,commonClass} = props;

  return (
    <div className="paragraphText" dangerouslySetInnerHTML={{ __html: text }} />
  )
}
export default ParagraphText;