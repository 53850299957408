import Link from "next/link";
import LinkButtonType from "./LinkButtonType";

import styles from "./LinkButton.module.scss";
import IconArrowSvg from "../../atoms/IconArrowSvg/IconArrowSvg";

const LinkButton = (props: LinkButtonType) => {
  const {id, text, url, hasBorder,hasBorderBottom} = props;
  return (
    <Link id={id} href={url} className={`${styles.linkButton} ${hasBorder? styles.has_border: ""} ${hasBorderBottom? styles.has_borderBottom: ""}`}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
      <IconArrowSvg 
        width={24}
        height={5}
      />
    </Link>
  )
}

export default LinkButton;