import parse from "html-react-parser";
import styles from "./GlobalNavigationItem.module.scss";
import GlobalNavigationItemType from "./GlobalNavigationItemType";

const GlobalNavigationItem = (props: GlobalNavigationItemType) => {
  const {id,text,url,icon,underBlank,isCurrent,hasSubMenu,isExternal,commonClass, sub} = props;
  return (
    <>
      <div className={`${styles.GlobalNavigationItem} GlobalNavigationItem`} id={id}>
        
        <a href={`${url}`} target={underBlank}>{parse(icon)}<span className={styles.GlobalNavigationItem__span}>{text}</span></a>
        {/* {sub && (
          <div className={styles.globalNavigationItems__subMenu}>
            <GlobalNavigationSubItems
              item={sub}
            />
          </div>
        )} */}
      </div>
    </>
  )
}
export default GlobalNavigationItem;