import styles from "./VerticalNavigationitem.module.scss";
import VerticalNavigationitemType from "./VerticalNavigationitemType";

const VerticalNavigationitem = (props: VerticalNavigationitemType) => {
  const {id,text,url,commonClass} = props;
  return (
    <>
      <div className={`${styles.VerticalNavigationitem} VerticalNavigationitem ${commonClass}`} id={id}>
        {url === '/' ? <a href="/">{text}</a> : <a href={`/${url}/`}>{text}</a>}
      </div>
    </>
  )
}
export default VerticalNavigationitem;