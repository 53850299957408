import styles from "./AddressText.module.scss";
import AddressTextType from "./AddressTextType";

const AddressText = (props: AddressTextType) => {
  const {id,zip,address1,address2,commonClass} = props;
  return (
    <>
      <p className={`${styles.addressText}`} id={id}>
        {zip && <>〒{zip}<br /></>}
        {address1}<br />
        {address2 && <>{address2}</>}
      </p>
    </>
  )
}
export default AddressText;