import ImageItemType from "./ImageItemType";

const ImageItem = (props: ImageItemType) => {
  const {id,imgPCPath,imgSPPath,webpPCPath,webpSPPath,widthPC,heightPC,widthSP,heightSP,altText,commonClass, isLazy} = props;

  return (
    <>
      <picture>
        {webpSPPath && <source srcSet={webpSPPath} media="(max-width:768px)" width={widthSP} height={heightSP} />}
        {webpPCPath && <source className={commonClass} srcSet={webpPCPath} width={widthPC} height={heightPC} id={id} />}
        {imgSPPath && <source srcSet={imgSPPath} media="(max-width:768px)" width={widthSP} height={heightSP} />}
        <img className={commonClass} src={imgPCPath} alt={altText} width={widthPC} height={heightPC} id={id} loading={isLazy ? 'lazy' : undefined} />
      </picture>
    </>
  )
}
export default ImageItem;