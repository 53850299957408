import styles from "./TelFax.module.scss";
import TelFaxType from "./TelFaxType";

const TelFax = (props: TelFaxType) => {
  const {id,number,numberType,commonClass} = props;
  const numberFormat = number.replaceAll("-","");
  const telFax = () => {
    if(numberType == "tel") {
      return(
        <span className={`${styles.telFax} telFax`} id={id}>TEL:<a href={`tel:${numberFormat}`}>{number}</a></span>
        
      );
    }else {
      return(
        <span className={`${styles.telFax} telFax`} id={id}>FAX:{number}</span>
      );
    }
  }
  return (
    <>
      {telFax()}
    </>
  )
}
export default TelFax;