import styles from "./SectionTitle.module.scss";
import SectionTitleType from "./SectionTitleType";

const SectionTitle = (props: SectionTitleType) => {
  const {id,text,subText,tagType:CustomTag="h2",commonClass} = props;
  return (
    <>
      <div className={`${styles.sectionTitle} sectionTitle`} id={id}>
        {text}
        {subText && 
        <span className={styles.sectionTitle__sub}>{subText}</span>
        }
      </div>
    </>
  )
}
export default SectionTitle;