import SNS from "../../../constants/siteInfo/sns";
import styles from "./SnsBlock.module.scss";

const SnsBlock = () => {
  return (
    <>
      <ul className={styles.snsBlock__list}>
        <li><a href={SNS.instagram} target="_blank" rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 26 26">
            <path id="icon_insta" d="M10.115,2.594a9.482,9.482,0,0,0-3.154.61A6.649,6.649,0,0,0,3.167,7.012a9.552,9.552,0,0,0-.6,3.157C2.508,11.553,2.494,12,2.5,15.528s.022,3.973.089,5.36a9.5,9.5,0,0,0,.61,3.153,6.645,6.645,0,0,0,3.81,3.795,9.544,9.544,0,0,0,3.156.6c1.384.061,1.829.075,5.358.068s3.974-.022,5.36-.088a9.512,9.512,0,0,0,3.152-.61,6.644,6.644,0,0,0,3.794-3.81,9.509,9.509,0,0,0,.6-3.155c.061-1.388.075-1.831.068-5.36s-.023-3.972-.088-5.359a9.5,9.5,0,0,0-.61-3.154,6.382,6.382,0,0,0-1.5-2.3A6.344,6.344,0,0,0,23.99,3.171a9.513,9.513,0,0,0-3.156-.6C19.45,2.513,19,2.5,15.474,2.5s-3.973.022-5.359.089m.152,23.5a7.214,7.214,0,0,1-2.414-.442,4.048,4.048,0,0,1-1.5-.97,4,4,0,0,1-.975-1.493,7.2,7.2,0,0,1-.452-2.414c-.064-1.37-.078-1.781-.086-5.252s.006-3.882.066-5.252a7.2,7.2,0,0,1,.442-2.414,4.026,4.026,0,0,1,.97-1.5,4.013,4.013,0,0,1,1.493-.975,7.2,7.2,0,0,1,2.413-.452c1.371-.065,1.782-.078,5.252-.086s3.882.005,5.254.066a7.19,7.19,0,0,1,2.414.442,4.3,4.3,0,0,1,2.472,2.463,7.162,7.162,0,0,1,.452,2.412c.065,1.371.08,1.782.086,5.252s-.006,3.882-.066,5.252a7.223,7.223,0,0,1-.442,2.415,4.306,4.306,0,0,1-2.463,2.472,7.2,7.2,0,0,1-2.412.452c-1.371.064-1.782.078-5.253.086s-3.881-.006-5.252-.066m10.6-17.54a1.56,1.56,0,1,0,1.557-1.563,1.56,1.56,0,0,0-1.557,1.563M8.824,15.516a6.675,6.675,0,1,0,6.662-6.688,6.675,6.675,0,0,0-6.662,6.688m2.342,0a4.333,4.333,0,1,1,4.342,4.325,4.333,4.333,0,0,1-4.342-4.325" transform="translate(-2.499 -2.503)" fill="#0085d0"/>
          </svg>
        </a></li>
      </ul>
    </>
  )
}
export default SnsBlock;