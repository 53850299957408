const CONTACT = {
  "companyName": "たなべ税理士事務所",//会社名
  "tellNumDisplayed": "092-753-7263",//電話番号（表示用）
  "tellNumDisplink": "tel:0927537263",//電話番号（リンク用）
  "faxNumDisplayed": "092-753-7264",//ファックス番号（表示用）
  "zip": "810-0012",//郵便番号
  "address_1": "福岡県福岡市中央区白金1-17-15",//住所1
  "address_2": "アレグリアオフィス白金6階",//住所2
  "businessHoursStart": "10:00",//始業時間
  "businessHoursEnd": "17:00",//終業時間
  "regularHoliday": "土・日・祝定休"//定休日
}

export default CONTACT;