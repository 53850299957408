import ParagraphText from "../../atoms/ParagraphText/ParagraphText";
import SectionContactType from "./SectionContactType";

import SectionComboTitle from "../../molecules/SectionComboTitle/SectionComboTitle";

import styles from "./SectionContact.module.scss";
import LinkButton from "../../molecules/LinkButton/LinkButton";

const SectionContact = (props: SectionContactType) => {
  const {id, sectionTitleItem, paragraphText, linkButton} = props;
  return (
    <section className={`${styles.sectionContact} gray`}>
      <div className={styles.sectionContact__inner}>
        <div className={styles.sectionContact__text}>
          <div className={styles.sectionContact__title}>
            <SectionComboTitle
              id={sectionTitleItem.id}
              sectionTitleText={sectionTitleItem.sectionTitleText}
              flashTitleText={sectionTitleItem.flashTitleText}
              direction={sectionTitleItem.direction}
            />
          </div>
          <ParagraphText
            id={paragraphText.id}
            text={paragraphText.text}
            commonClass={paragraphText.commonClass}
          />
        </div>
        <div className={styles.sectionContact__LinkButton}>
          <LinkButton 
            id={linkButton.id}
            text={linkButton.text}
            url={linkButton.url}
            hasBorder={linkButton.hasBorder}
          />
        </div>
      </div>
    </section>
  )
}

export default SectionContact;