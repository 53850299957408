"use client";
import styles from "./HeaderBlock.module.scss";

import HamburgerButton from "../../atoms/HamburgerButton/HamburgerButton";
import LogoBlock from "../../molecules/LogoBlock/LogoBlock";
import GlobalNavigationItems from "../../molecules/GlobalNavigationItems/GlobalNavigationItems";
import useToggleHamburger from "../../../hooks/useToggleHamburger.hooks";
import HamburgerNavigation from "../HamburgerNavigation/HamburgerNavigation";
import HeaderBlockType from "./HeaderBlockType";

const HeaderBlock = (props: HeaderBlockType) => {
  const {id, headerLogo, globalNavigationItems, hamburgerItems} = props;

  const { isHamburgerOpen, toggleIsHamburgerOpen } = useToggleHamburger();

  return (
    <div id={id} className={styles.headerBlock}>
      <div className={styles.headerBlock__logoBlock}>
        <LogoBlock
          id={headerLogo.id}
          url={headerLogo.url}
          imageItem={{
            id: headerLogo.id,
            imgPCPath: headerLogo.imageItem.imgPCPath,
            altText: headerLogo.imageItem.altText,
            commonClass: headerLogo.imageItem.commonClass
          }}
        />
      </div>
      <div className={styles.headerBlock__inner}>
        <GlobalNavigationItems
          id={globalNavigationItems.id}
          globalNavigationItems={globalNavigationItems.globalNavigationItems}
        />
        <HamburgerButton
          isHamburgerOpen={isHamburgerOpen} toggleIsHamburgerOpen={toggleIsHamburgerOpen}
        />
      </div>

      {isHamburgerOpen && (
        <HamburgerNavigation
          hamburgerNavigationItems={{
            id: hamburgerItems.hamburgerNavigationItems.id,
            navItems: hamburgerItems.hamburgerNavigationItems.navItems,
            columnNumbers: hamburgerItems.hamburgerNavigationItems.columnNumbers,
          }}
          imageItem={{
            id: hamburgerItems.imageItem.id,
            imgPCPath: hamburgerItems.imageItem.imgPCPath,
            altText: hamburgerItems.imageItem.altText
          }}
        />
      )}
    </div>
  )
}

export default HeaderBlock;