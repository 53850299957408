import { HamburgerButtonType } from "./HamburgerButtonType";

import styles from "./HamburgerButton.module.scss"

const HamburgerButton = (props: HamburgerButtonType) => {
  const {id, isHamburgerOpen, toggleIsHamburgerOpen, commonClass} = props; 

  return (
    <button 
      className={`${styles.hamburgerButton}`} 
      id={id} 
      onClick={toggleIsHamburgerOpen}
      aria-expanded={isHamburgerOpen}
      aria-label="HamburgerButton"
    >
      <span className={styles.line__top}></span>
      <span className={styles.line__middle}></span>
      <span className={styles.line__bottom}></span>
    </button>


  )
}

export default HamburgerButton;