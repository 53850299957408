import AddressText from "../../atoms/AddressText/AddressText";
import FooterTelFax from "../FooterTelFax/FooterTelFax";
import FooterInfoType from "./FooterInfoType";

import styles from "./FooterInfo.module.scss"

const FooterInfo = (props: FooterInfoType) => {
  const {id, addressText, footerTelFax} = props;
  return (
    <div id={id}>
      <AddressText
        id={addressText.id}
        zip={addressText.zip}
        address1={addressText.address1}
        address2={addressText.address2}
        commonClass={addressText.commonClass}
      />
      <FooterTelFax
        id={footerTelFax.id}
        telNumber={footerTelFax.telNumber}
        faxNumber={footerTelFax.faxNumber}
      />
    </div>
  )
}

export default FooterInfo;