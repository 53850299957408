import FlashTitle from "../../atoms/FlashTitle/FlashTitle";
import SectionTitle from "../../atoms/SectionTitle/SectionTitle";
import SectionComboTitleType from "./SectionComboTitleType";

import styles from "./SectionComboTitle.module.scss";

const SectionComboTitle = (props: SectionComboTitleType) => {
  const {id, sectionTitleText, flashTitleText, direction} = props;
  return (
    <div id={id} className={`sectionTitle__${direction}`}>
      <SectionTitle
        id={sectionTitleText.id}
        text={sectionTitleText.text}
        subText={sectionTitleText.subText}
        tagType={sectionTitleText.tagType}
        commonClass={sectionTitleText.commonClass}
      />
      <FlashTitle
        id={flashTitleText.id}
        text={flashTitleText.text}
        commonClass={flashTitleText.commonClass}
      />
    </div>
  )
}

export default SectionComboTitle;