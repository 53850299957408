import React from 'react';
import plt_countPageKeys from '../../../libs/plt_countPageKeys';
import VerticalNavigationitem from '../../atoms/VerticalNavigationitem/VerticalNavigationitem';
import VerticalNavigationitemSub from '../../atoms/VerticalNavigationitemSub/VerticalNavigationitemSub';
import VerticalNavigationitemsType from './VerticalNavigationitemsType';

import styles from "./VerticalNavigationitems.module.scss"

const VerticalNavigationitems = (props: VerticalNavigationitemsType) => {
  const { id, navItems } = props;

  return (
    <>
      {navItems && (
        <ul id={id}>
          {Object.keys(navItems).map((item, key) => {
            const pageInfo = navItems[item];
            return (
              <li key={key}>
                <VerticalNavigationitem
                  id={pageInfo?.TITLE}
                  text={pageInfo?.TITLE || item}
                  url={pageInfo?.SLUG || item}
                  commonClass=""
                />
                {pageInfo?.SUB && typeof pageInfo.SUB === 'object' && (
                  <ul>
                    {Object.keys(pageInfo.SUB).map((subItem, key) => {
                      const subPageInfo = pageInfo.SUB?.[subItem];
                      return (
                        <li key={key}>
                          <VerticalNavigationitemSub
                            id={subPageInfo?.TITLE}
                            text={subPageInfo?.TITLE || subItem}
                            url={subPageInfo?.SLUG || subItem}
                            commonClass=""
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default VerticalNavigationitems;
