import styles from "./IconArrowSvg.module.scss";
import IconArrowSvgType from "./IconArrowSvgType";

const IconArrowSvg = (props: IconArrowSvgType) => {
  const {id,width,height,commonClass} = props;
  return (
    <>
      <svg className={`${styles.iconArrowSvg}`} xmlns="http://www.w3.org/2000/svg" id={id} width={width} height={height} viewBox="0 0 33.036 8.569">
        <g data-name="IconArrowSvg" transform="translate(0 0.311)">
          <path className={styles.iconArrowSvg__path} data-name="iconArrowSvg__path" d="M5232.8,2749.284h32l-6.167-7.758" transform="translate(-5232.801 -2741.526)" fill="none" strokeWidth="1"/>
        </g>
      </svg>
    </>
  )
}
export default IconArrowSvg;