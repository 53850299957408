import FooterBox from "../FooterBox/FooterBox";
import FooterType from "./FooterType";
import styles from "./Footer.module.scss";

const Footer = (props: FooterType) => {
  const {id, footerLogo, footerInfo, footerNavigation, copyright} = props;

  return (
    <footer id="Footer" className={styles.footer}>
      <FooterBox
        id={id}
        footerLogo={{
          id: footerLogo.id,
          url: footerLogo.url,
          imageItem: {
            imgPCPath: footerLogo.imageItem.imgPCPath,
            altText: footerLogo.imageItem.altText
          }
        }}
        footerInfo={{
          id: footerInfo.id,
          addressText: {
            zip: footerInfo.addressText.zip,
            address1: footerInfo.addressText.address1,
            address2: footerInfo.addressText.address2
          },
          footerTelFax: {
            telNumber: {
              number: footerInfo.footerTelFax.telNumber.number,
              numberType: footerInfo.footerTelFax.telNumber.numberType
            },
            faxNumber: {
              number: footerInfo.footerTelFax.faxNumber.number,
              numberType: footerInfo.footerTelFax.faxNumber.numberType
            }
          }
        }}
        footerNavigation={{
          navItems: footerNavigation.navItems,
          columnNumbers: footerNavigation.columnNumbers,
        }}
        copyright={{
          text: copyright.text
        }}
      />
    </footer>
  )
}
export default Footer;