"use client";

import { useState, MouseEvent } from "react";
import { HamburgerButtonStateType } from "../components/atoms/HamburgerButton/HamburgerButtonType";

const useToggleHamburger = (): HamburgerButtonStateType => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const toggleIsHumbergerOpen: HamburgerButtonStateType["toggleIsHamburgerOpen"] = (event: MouseEvent<HTMLButtonElement>) => {
    setIsHamburgerOpen((previousState) => !previousState);
  };

  return {
    isHamburgerOpen,
    toggleIsHamburgerOpen: toggleIsHumbergerOpen,
  };
};

export default useToggleHamburger;