"use client";

import { useEffect, useState } from "react";
import Footer from "../../../components/organisms/Footer/Footer";
import SectionContactBlock from "../SectionContactBlock/SectionContactBlock";
import siteFooterContext from "./SiteFooterContext";

const SiteFooter = () => {
  const [currentPath, setCurrentPath] = useState("");
  const [isRenderSectionContactBlock, setIsRenderSectionContactBlock] = useState(true);

  useEffect(() => {
    setCurrentPath(decodeURIComponent(window.location.pathname))
    currentPath.includes('/contact/') ? setIsRenderSectionContactBlock(false) : setIsRenderSectionContactBlock(true);

  },[currentPath])
  return (
    <>
      {isRenderSectionContactBlock ? (
        <SectionContactBlock />
      ) : null}
      <Footer
        id={siteFooterContext.id}
        footerLogo={siteFooterContext.footerLogo}
        footerInfo={siteFooterContext.footerInfo}
        footerNavigation={siteFooterContext.footerNavigation}
        copyright={siteFooterContext.copyright}
      />
    </>
  )
}

export default SiteFooter;