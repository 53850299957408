import styles from "./FlashTitle.module.scss";
import FlashTitleType from "./FlashTitleType";

const FlashTitle = (props: FlashTitleType) => {
  const {id,text,commonClass} = props;
  return (
    <>
      <div className={`${styles.flashTitle} flashTitle`} id={id}>{text}</div>
    </>
  )
}
export default FlashTitle;