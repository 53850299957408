import styles from "./Copyright.module.scss";
import CopyrightType from "./CopyrightType";

const Copyright = (props: CopyrightType) => {
  const {id,text,commonClass} = props;
  return (
    <>
      <small className={`${styles.copyright}`} id={id}>{text}</small>
    </>
  )
}
export default Copyright;