import FooterBoxType from "../../../components/organisms/FooterBox/FooterBoxType";
import CONTACT from "../../../constants/siteInfo/contact";
import META from "../../../constants/siteInfo/meta";
import PAGE_INFO from "../../../constants/siteInfo/pageInfo";

const siteFooterContext: FooterBoxType = {
  footerLogo: {
    url: META.siteUrl,
    imageItem: {
      imgPCPath: "/images/layout/header/logo_pc.svg",
      altText: META.siteTitle
    }
  },
  footerInfo: {
    addressText: {
      zip: CONTACT.zip,
      address1: CONTACT.address_1,
      address2: CONTACT.address_2
    },
    footerTelFax: {
      telNumber: {
        number: CONTACT.tellNumDisplayed,
        numberType: "tel"
      },
      faxNumber: {
        number: CONTACT.faxNumDisplayed,
        numberType: "fax"
      }
    }
  },
  footerNavigation: {
    navItems: PAGE_INFO,
    columnNumbers: 3,
  },
  copyright: {text:META.copyright}
}

export default siteFooterContext;