import Link from "next/link";
import ImageItem from "../../atoms/ImageItem/ImageItem";
import LogoBlockType from "./LogoBlockType";

import styles from "./HeaderLogo.module.scss";

const LogoBlock = (props: LogoBlockType) => {
  const {id, url, imageItem} = props;
  return (
    <>
      <Link href={url} id={id}>
        <ImageItem
          id={imageItem.id}
          imgPCPath={imageItem.imgPCPath}
          imgSPPath={imageItem.imgSPPath}
          webpPCPath={imageItem.webpPCPath}
          webpSPPath={imageItem.webpSPPath}
          widthPC={imageItem.widthPC}
          heightPC={imageItem.heightPC}
          widthSP={imageItem.widthSP}
          heightSP={imageItem.heightSP}
          altText={imageItem.altText}
          commonClass={imageItem.commonClass}
        />
      </Link>
    </>
  )
}

export default LogoBlock;