import TelFax from "../../atoms/TelFax/TelFax";
import FooterTelFaxType from "./FooterTelFaxType";

import styles from "./FooterTelFaxWrapper.module.scss";

const FooterTelFax = (props: FooterTelFaxType) => {
  const {id, telNumber, faxNumber} = props;
  return (
    <div id={id}>
      <TelFax 
        id={telNumber.id}
        number={telNumber.number}
        numberType={telNumber.numberType}
        commonClass={telNumber.commonClass}
      />
      /
      <TelFax
        id={faxNumber.id}
        number={faxNumber.number}
        numberType={faxNumber.numberType}
        commonClass={faxNumber.commonClass}
      />
    </div>
  )
}
export default FooterTelFax;