import GlobalNavigationItem from "../../atoms/GlobalNavigationItem/GlobalNavigationItem";
import styles from "./GlobalNavigationItems.module.scss";
import { HeaderNavigationType } from "./GlobalNavigationItemsType";

const GlobalNavigationItems = (props: HeaderNavigationType) => {
  const {id, globalNavigationItems} = props;

  return (
    <>
      {globalNavigationItems && (
        <ul className={styles.globalNavigationItems} id={id}>
          {Object.keys(globalNavigationItems).map((item, key) => {
            const navItem = globalNavigationItems[item];
            return (
              <li key={navItem?.id || key}>
                <GlobalNavigationItem 
                  id={navItem?.id}
                  icon={navItem.icon}
                  text={navItem.text}
                  url={navItem.url}
                  underBlank={navItem.underBlank}
                  hasSubMenu={navItem.hasSubMenu}
                  isExternal={navItem.isExternal}
                  commonClass={navItem.commonClass}
                  sub={navItem.sub}
                />
              </li>
            )
          })}
        </ul>
      )}

    </>
  )
}

export default GlobalNavigationItems;