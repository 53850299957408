import styles from "./VerticalNavigationitemSub.module.scss";
import VerticalNavigationitemSubType from "./VerticalNavigationitemSubType";

const VerticalNavigationitemSub = (props: VerticalNavigationitemSubType) => {
  const {id,text,url,commonClass} = props;
  return (
    <>
      <div className={`${styles.VerticalNavigationitemSub} VerticalNavigationitemSub ${commonClass}`} id={id}>
        <a href={`/${url}/`}>{text}</a>
      </div>
    </>
  )
}
export default VerticalNavigationitemSub;