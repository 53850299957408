import SectionContact from "../../../components/organisms/SectionContact/SectionContact";

const SectionContactBlock = () => {
  return (
    <SectionContact
      sectionTitleItem={{
        id: "sectionTitleTextId",
        sectionTitleText: {
          text: "Contact",
          tagType: "h2",
          commonClass: "section-title-text-class"
        },
        flashTitleText: {
          text: "お問い合わせ"
        },
        direction: "column"
      }}
      paragraphText={{
        text: "福岡で税務や会計についてわからないこと<br class='pc-none' />お気軽にご相談ください。<br />福岡市を中心に福岡県近郊エリアまで<br class='pc-none'/>ご対応可能です。</p>"
      }}
      iconArrowSvg={{
        width: 200,
        height: 200,
      }}
      linkButton={{
        text:"お問い合わせはこちら",
        url:"/contact/",
        hasBorder:true
      }}
    />
  )
}

export default SectionContactBlock;